import * as axios from "../network/Network";

const config = require("../network/api.json");

class HeaderService {
  static header() {
    return axios.appRequest({
      method: axios.GET,
      url: config.general.header,
      data: "",
    });
  }
}
export { HeaderService };
