import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";

const useForgotPassword = () => {
    const [mobile, setMobile] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [forgotPasswordData, setForgotPasswordData] = useState<any>({});
    const dispatch = useDispatch();
    const history = useNavigate();

    useEffect(() => {
        if (forgotPasswordData?.data?.status?.code === 0) {
            history("/getOtp", {
                state: { mobile: mobile },
            });

            setMobile("");

            setSubmitted(false);
            setTimeout(() => {
                dispatch(
                    alertActions.success(forgotPasswordData?.data?.status?.message)
                );
            }, 50);
        } else {
            dispatch(alertActions.error(forgotPasswordData?.data?.status?.message));
        }
        // eslint-disable-next-line 
    }, [forgotPasswordData]);

    //sumbit button to get otp
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmitted(true);
        var filter = /^[0-9]{10}$/;
        if (mobile) {
            if (!filter.test(mobile)) {
                dispatch(alertActions.error("Enter valid Mobile Number"));
            } else {
                UserService.forgotPassword(mobile)
                    .then((response) => {
                        setForgotPasswordData(response);
                    })
                    .catch((error) => {
                        console.log("Errorreg", error);
                    });
            }
        }
    }


    function handleChange(e: any) {
        setMobile(e.target.value);
    }

    return {
        mobile,
        handleSubmit,
        handleChange,
        submitted
    }
}
export default useForgotPassword;