import { useState } from "react";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./ProductList.css"
import useProductList from "./useProductList";
import { collection, defaultimg, loader, noitem } from "../../../assets/images"
import { commaValidation } from "../../../utility";
import { useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import { withTranslation } from "react-i18next";

const ProductList = (props) => {
    const { t } = props;
    const location = useLocation();
    let show3 = false;
    // const [show3, setShow3] = useState(false);
    const [filter, setFilter] = useState(false);
    /**
     * useproductlist hook here passing location info to hooks
     */
    const { menudata,
        productList,
        image_url,
        pageCount,
        current,
        search,
        loaderdata,
        viewDetail,
        gotoHome,
        changeCurrentPage,
        handleChange,
        isInMainCategory,
        isInSubCategory,
        changeHandler,
    } = useProductList(location);

    /**
     * show filter
     */
    const showFilter = () => {
        setFilter(!filter);
    }
    /**
     * render header bar
     * @returns 
     */
    const renderHeaderBar = () => {
        return (
            <div>
                <HeaderScreen />
            </div>

        )
    }
    /**
     * display banner
     * @returns 
     */
    const renderCollectionBanner = () => {
        return (
            <div className="collection_banner flt">
                <img src={collection} alt="no_img" />

            </div>
        )
    }
    //render filter part
    const renderFilterPart = () => {
        return (
            <div className="col-md-2">
                <div className="product_left flt">
                    <div className="breadcrumbs flt">
                        <span onClick={gotoHome}>{t("home")}</span> {t("collection")}  <div className="filler hide_desktop"></div> <button className="filter_btn hide_desktop" onClick={showFilter}><i className="fa fa-filter" aria-hidden="true"></i> Filter</button>
                    </div>
                    <div className={"product_filter flt" + (filter ? " filter_display" : '')}>
                        <div className="filter_box flt">
                            <div className="filter_head flt">
                                <span>
                                    {t("main_category")}
                                </span>
                            </div>
                            <div className=" flt">
                                <div className="filter_cont flt" >
                                    <form>
                                        {menudata && menudata?.category && menudata?.category.map((main_cat) =>
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id={main_cat.title.toLowerCase()}
                                                    value={main_cat.title}
                                                    onChange={handleChange}
                                                    name="main"
                                                    checked={isInMainCategory(main_cat.title)}
                                                />
                                                <label htmlFor={main_cat?.title.toLowerCase()}>{main_cat?.title}</label>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="filter_box flt">
                            <div className="filter_head flt">
                                <span>

                                    {t("product_category")}
                                </span>
                            </div>
                            <div className=" flt">
                                <div className={"filter_cont flt" + (show3 ? " show" : 'show')}>
                                    <form>
                                        {menudata && menudata?.sub_category && menudata?.sub_category.map((sub_cat) =>
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id={sub_cat.title.toLowerCase()}
                                                    onChange={handleChange}
                                                    value={sub_cat.title}
                                                    checked={isInSubCategory(sub_cat.title)}
                                                    name="sub"
                                                />
                                                <label htmlFor={sub_cat?.title.toLowerCase()}>{sub_cat?.title}</label>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    //render display image part
    const renderDisplayImage = () => {
        return (
            <div className="col-md-10">

                <div className="product_right flt">

                    <div className="product_bar flt">
                        <span><input
                            type="text"
                            placeholder={t("search_here")}
                            name="search"
                            value={search && search}
                            onChange={changeHandler}
                        /></span>
                    </div>
                    <div className="product_list flt">
                        {loaderdata !== "isfull" && loadingDisplay()}
                        <div className="row">

                            {productList && productList?.length > 0 && productList.map((product) =>
                                <div className="col-md-4">
                                    <div className="product_box flt" onClick={() => viewDetail(product)}>
                                        <div className="product_image flt">
                                            <span>
                                                <img src={product?.pro_image?.image ? image_url + product?.pro_image?.image : defaultimg} alt="no_image" />
                                            </span>

                                        </div>
                                        <div className="product_txt flt">
                                            <div className="product_desc flt">
                                                <div className="product_name">{product?.name}</div>
                                                <div className="product_rate">{t("rs")} <span>{commaValidation(product?.c_gross_value)}</span></div>
                                            </div>
                                            <div className="product_weight flt">{commaValidation(product?.pro_details?.weight)}{t("gm")}</div>
                                        </div>
                                    </div>
                                </div>

                            )}
                            {productList && productList?.length <= 0 &&
                                <div className="col-sm-12">
                                    <div className="no_data_img flt">
                                        <div className="nodata flt">
                                            <img src={noitem} alt="noimage" />

                                        </div>
                                        <div className="nodata_text flt">
                                            No data found
                                        </div>
                                    </div>
                                </div>
                            }




                        </div>
                    </div>
                    <div className="paginationdiv flt">
                        <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            currentPage={current}
                            showFirstLastPages={true}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={changeCurrentPage}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            forcePage={current - 1}
                        />
                    </div>
                </div>
            </div>
        )
    }
    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }


    //loading image display 
    const loadingDisplay = () => {
        return (
            <div className="product_loader flt">
                <img src={loader} alt="populargold" />
            </div>
        );
    };

    return (
        <>

            {renderHeaderBar()}
            {renderCollectionBanner()}
            <div className="product_container flt">
                <div className="container">
                    <div className="row">
                        {renderFilterPart()}
                        {renderDisplayImage()}
                    </div>
                </div>
            </div>
            {renderFooter()}
        </>
    )
}
export default withTranslation()(ProductList);