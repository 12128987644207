import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ProductService } from "../../../services";

const useMyOrder = () => {
    const [myOrder, setMyOrder] = useState<any>([]);
    const [loaderdata, setLoaderdata] = useState("notset");
    const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
    const user_id = useSelector((state:RootStateOrAny) => state?.userData?.user?._id);
    const navigate = useNavigate();
    useEffect(() => {
        ProductService.ongoinOrder().then((response) => {
            if (response?.data?.status?.code === 0) {
                setMyOrder(response?.data?.data?.orders)
                setLoaderdata("isfull");
            } else {
                setLoaderdata("isempty");
            }
        }).catch((err) => console.log(err))
    }, [])
    const downloadInvoice = (id:any) => {

        if (user_id) {
            var link = document.createElement("a");

            link.setAttribute("download", "Invoice.pdf");
            link.href =
                `https://api.populargold.in/api/v1/print/printOrder/` + id;
            // link.href = "data:application/pdf;base64," + baseData;

            document.body.appendChild(link);
            link.setAttribute("target", "_blank");
            // link.download = "file.pdf";
            link.click();
            link.remove();
        } else {
            navigate("/login")
        }
    }
    const gotoHome = () => {
        navigate("/");
    }
    return {
        myOrder,
        image_url,
        loaderdata,
        gotoHome,
        downloadInvoice
    }
}
export default useMyOrder;