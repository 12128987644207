
import { RootStateOrAny, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const useHome = () => {
  const menudata = useSelector((state:RootStateOrAny) => state?.menuData?.menu);
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
  const navigate = useNavigate();
  /**
   * getting whats new value
   */
  const getNewId = (data:any) => {
    navigate(`/product-list/${data}`, {
      state: { newName: data },
    });
  }

  //from gold section to productlist
  const getGoldDetails = (data:any, subdata:any) => {
    navigate(`/product-list/${data + " " + subdata}`, {
      state: { name: data, subdata: subdata },
    });
  }


  return {
    menudata,
    image_url,
    getNewId,
    getGoldDetails
  };
}
export default useHome;