import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserService {
  static login(mobile_no, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.login,
      data: { mobile_no, password },
    });
  }
  static registration(first_name, mobile_no, email, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.register,
      data: { first_name, mobile_no, email, password },
    });
  }
  static userOtp(mobile_number, email) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.userOtp,
      data: { mobile_number, email },
    });
    return Promise.resolve(result);
  }
  static verifyOtp(otp, mobile_no) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.verifyOtp,
      data: { otp, mobile_no },
    });
    return Promise.resolve(result);
  }
  static verifyEmailMobile(mobile_number, email) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.check_email_mobile,
      data: { mobile_number, email },
    });
    return Promise.resolve(result);
  }

  static forgotPassword(mobile) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.forgotPassword,
      data: {
        mobile,
      },
    });
    return Promise.resolve(result);
  }

  static getOtp(otp, new_password, mobile) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.getOtp,
      data: {
        otp,
        new_password,
        mobile,
      },
    });

    return Promise.resolve(result);
  }
}

export { UserService };
