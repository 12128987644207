import { logo, close_pop } from "../../../assets/images";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./Registration.css";
import useRegistration from "./useRegistration";
import { withTranslation } from "react-i18next";
const RegistrationScreen = (props: any) => {
    const { t } = props;
    const {
        name,
        email,
        mobile_no,
        password,
        re_password,
        submitted,
        otp,
        showOtp,
        redirectLogin,
        setOtp,
        changeHandler,
        handleOtpSubmit,
        closeOtp,
        changeOtpHandler,
        verifyOtp
    } = useRegistration(t);

    /**
     * 
     * @returns render header
     */
    const renderHeader = () => {
        return (
            <div className="main_login flt">
                <HeaderScreen />
            </div>
        );
    };

    /**
     * 
     * @returns render registration screen
     */
    const renderRegistration = () => {
        return (
            <div className="login_main flt">
                <div className="login_form login_form_reg">
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="main_block">
                                <div className="welcome_reg flt">
                                    {t("welcome")}
                                    <div className="details">{t("Enter_your_Details_to_sign_up")}</div>
                                </div>
                                <div className="mob_number mob_numbers flt">
                                    <label>{t("full_name")}</label>
                                    <input
                                        type="text"
                                        placeholder={t("name_placeholder")}
                                        name="name"
                                        value={name}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleOtpSubmit(e);
                                            }
                                        }}
                                        onChange={changeHandler}
                                        className={submitted && !name ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !name && (
                                    <div className="invalid-feedback">{t("name_required")}</div>
                                )}

                                <div className="mob_number flt">
                                    <label>{t("Mobile number")}</label>
                                    <input
                                        type="number"
                                        placeholder={t("mobile_placeholder")}
                                        name="mobile_no"
                                        value={mobile_no}
                                        onChange={changeHandler}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleOtpSubmit(e);
                                            }
                                        }}
                                        className={submitted && !mobile_no ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !mobile_no && (
                                    <div className="invalid-feedback invalid-mob">
                                        {t("mobile_required")}
                                    </div>
                                )}

                                <div className="mob_number flt">
                                    <label>{t("email")}</label>
                                    <input
                                        type="text"
                                        placeholder={t("email_placeholder")}
                                        name="email"
                                        value={email.trim()}
                                        onChange={changeHandler}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleOtpSubmit(e);
                                            }
                                        }}
                                        className={submitted && !email ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !email && (
                                    <div className="invalid-feedback">{t("email_required")}</div>
                                )}
                                <div className="mob_number flt">
                                    <label>{t("password")}</label>
                                    <input
                                        type="password"
                                        placeholder={t("password_placeholder")}
                                        name="password"
                                        value={password}
                                        onChange={changeHandler}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleOtpSubmit(e);
                                            }
                                        }}
                                        className={submitted && !password ? " is-invalid" : ""}

                                    />
                                </div>
                                {submitted && !password && (
                                    <div className="invalid-feedback">{t("password_required")}</div>
                                )}
                                <div className="mob_number flt">
                                    <label>{t("conf_password")}</label>
                                    <input
                                        type="password"
                                        placeholder={t("cpassword_placeholder")}
                                        name="re_password"
                                        value={re_password}
                                        onChange={changeHandler}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleOtpSubmit(e);
                                            }
                                        }}
                                        className={submitted && !re_password ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !re_password && (
                                    <div className="invalid-feedback">
                                        {t("cpassword_required")}
                                    </div>
                                )}


                                <div className="login_btn flt">
                                    <button className="login" onClick={handleOtpSubmit} >{t("register")}</button>
                                </div>
                                <div className="forgot_password_reg flt">
                                    {t("Already_have_an_account")}<span onClick={redirectLogin}>{t("login")}</span>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="image_sub1 flt">
                                <img src={logo} alt="pop_background" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    /**
     * 
     * @returns verify otp
     */
    const OtpVerify = () => {
        return (
            <div className="modalPop" id="enquiryModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" onClick={closeOtp}>
                                <img src={close_pop} alt="no_image" />
                            </button>
                            <div className="enquiry_head flt">{t("verify_otp")}</div>
                            <div className="enquiry_cont flt">
                                <div className="mode_box flt">
                                    <div className="mode_tag flt">
                                        {t("enter_otp")}

                                    </div>
                                    <div className="form-group flt">
                                        <input
                                            type="number"
                                            placeholder={t("enter_otp")}
                                            name="otp"
                                            value={otp}
                                            onChange={changeOtpHandler}
                                            onKeyDown={(e: any) => {
                                                if (e.key === "Enter") {
                                                    setOtp(e.target.value);
                                                    verifyOtp(e);
                                                }
                                            }}

                                            onWheelCapture={(e: any) => {
                                                e.target.blur();
                                            }}
                                            className={submitted && !otp ? " is-invalid" : ""}
                                        />
                                        {submitted && !otp && (
                                            <div className="invalid-feedback">{t("otp_required")}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="mode_btn flt">
                                    <button onClick={verifyOtp}>{t("Submit")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <>
            {renderHeader()}
            {renderRegistration()}
            {showOtp && OtpVerify()}
            {renderFooter()}
        </>
    )
}
export default withTranslation()(RegistrationScreen);