import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";
import { ProductService } from "../../../services";

const useCheckout = (location: any, displayRazorpay: any, t: any) => {
    const dispatch = useDispatch();
    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [alternateMobile, setAlternateMobile] = useState("");
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [landmarkValue, setLandmarkValue] = useState("");
    const [pincode, setPincode] = useState("");
    const [district, setDistrict] = useState("");
    const [storeState, setStoreState] = useState([]);
    const [storeDistrict, setStoreDistrict] = useState([]);
    const [checkOutData, setCheckOutData] = useState({});
    const product_id = location?.state?.product_id;
    const total_amount = location?.state?.total_amount;
    const gstAmount = location?.state?.total_amount;

    //useEFFECT
    useEffect(() => {
        ProductService.getState()
            .then((storeState) => {
                setStoreState(storeState.data.data);
            })
            .catch((error) => {
                console.log("Errorreg", error);
            });
    }, []);

    useEffect(() => {
        displayRazorpay(checkOutData, product_id);
        // eslint-disable-next-line
    }, [checkOutData])
    /**
     * 
     * @param {CHANGE HANDLER VFOR INPUIT VALUES} e 
     */
    const changeHandler = (e: any) => {
        //store name onChange
        if (e.target.name === "name") {
            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                setName(e.target.value);
            } else if (!e.target.value) {
                setName("");
            }
        }
        /**
         * check mobile number
         */
        if (e.target.name === "mobile") {
            setMobile(e.target.value)
        }
        /**
        * check mobile
        */
        if (e.target.name === "alternateMobile") {
            setAlternateMobile(e.target.value)
        }
        /**
        * check address
        */
        if (e.target.name === "address") {
            setAddress(e.target.value)
        }
        /**
        * check locality
        */
        if (e.target.name === "locality") {
            setLocality(e.target.value)
        }
        /**
        * check state 
        * getting id from state 
        * pass this ti get district api
        * get district
        * store it in state
        * map 
        */
        if (e.target.name === "state") {
            let id = JSON.parse(e.target.value);
            setState(id?.state)
            ProductService.getDistrict(id?._id).then((response) => {
                setStoreDistrict(response?.data?.data)
            })
        }
        /**
        * check district
        */
        if (e.target.name === "district") {
            let id = JSON.parse(e.target.value);
            setDistrict(id?.district)
        }
        /**
        * check city
        */
        if (e.target.name === "city") {
            setCity(e.target.value)
        }
        /**
        * check pincode
        */
        if (e.target.name === "pincode") {
            if (e.target.value.length > 6) {
                dispatch(alertActions.error("Enter valid pincode number"));
            } else {
                setPincode(e.target.value);
            }
        }
        /**
        * check landmark
        */
        if (e.target.name === "landmarkValue") {
            setLandmarkValue(e.target.value)
        }


    }
    /**
     * 
     * @param {*} e
     * submit all data for api call 
     */
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmitted(true);
        /**
         * check for mobile number format
         */
        var filter = /^[0-9]{10}$/;
        if (
            name &&
            mobile &&
            pincode &&
            address &&
            city &&
            state
        ) {
            if (mobile && !filter.test(mobile)) {
                dispatch(alertActions.error(t("enter_valid_mobile")));
            } else if (pincode && pincode.length !== 6) {
                dispatch(alertActions.error(t("valid_pincode")));
            } else if (alternateMobile && !filter.test(alternateMobile)) {
                dispatch(alertActions.error(t("enter_alt_mob")));
            } else if (!district && storeDistrict.length !== 0) {
                dispatch(alertActions.error(t("select_districts")));
            } else {
                // setLoading(true);
                ProductService.checkout(
                    name,
                    mobile,
                    city,
                    pincode,
                    address,
                    state,
                    product_id,
                    total_amount,
                    gstAmount,
                    district
                )
                    .then((response) => {
                        setCheckOutData(response);
                        // setLoading(false);
                    })
                    .catch((error) => {
                        console.log("Errorreg", error);
                    });
            }
        }
    }
    return {
        name,
        mobile,
        alternateMobile,
        address,
        locality,
        city,
        state,
        landmarkValue,
        pincode,
        district,
        submitted,
        storeState,
        storeDistrict,
        checkOutData,
        changeHandler,
        handleSubmit
    }
}
export default useCheckout;