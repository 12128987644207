import useContact from "./useContact";
import "./Contact.css";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import contactBanner from "../../../assets/images/contact.png";
import { withTranslation } from "react-i18next";
const Contact = (props: any) => {
  const { changeHandler, add, submitted, handleSubmit, gotoHome } =
    useContact();
  const { t } = props;
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen />
      </div>
    );
  };
  /**
   *
   * @returns render banner image
   */
  const renderBanner = () => {
    return (
      <div className="banner_container flt">
        <img src={contactBanner} className="img-responsive" alt="no_image" />
      </div>
    );
  };
  //render map

  const renderMap = () => {
    return (
      <div className="col-sm-6">
        <div className="map_show flt">
          <div className="address_head flt">{t("address_title")}</div>
          <ul className="address_box flt">
            <li>{t("contact_no")} </li>
            <li>{t("contact_mail")}</li>
            <li
              dangerouslySetInnerHTML={{ __html: `${t("contact_address")}` }}
            ></li>
          </ul>
          <iframe
            title="mapdisplay"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d37003.11881546496!2d74.83089427671038!3d12.877230197450988!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6d99f08cc55ecdbc!2sPopular%20Gold%20Mart!5e0!3m2!1sen!2sin!4v1659678946327!5m2!1sen!2sin"
            width="600"
            height="220"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  };

  //render input data
  const renderContactmain = () => {
    return (
      <div className="col-sm-5">
        <div className="contactMain flt">
          <div className="welcome flt">{t("contactus")}</div>
          <div className="mob_number flt">
            <label>{t("name")}</label>
            <input
              name="name"
              value={add && add.name}
              placeholder={t("name_placeholder")}
              onChange={changeHandler}
              className={submitted && add && !add.name ? " is-invalid" : ""}
            />
          </div>
          {submitted && add && !add.name && (
            <div className="invalid-feedback">{t("name_required")}</div>
          )}
          <div className="mob_number flt">
            <label>{t("Mobile number")}</label>
            <input
              type="number"
              name="mobile_no"
              value={add && add.mobile_no}
              placeholder={t("mobile_placeholder")}
              onChange={changeHandler}
              className={
                submitted && add && !add.mobile_no ? " is-invalid" : ""
              }
            />
          </div>
          {submitted && add && !add.mobile_no && (
            <div className="invalid-feedback">{t("mobile_required")}</div>
          )}
          <div className="mob_number flt">
            <label>{t("email")}</label>
            <input
              name="email"
              value={add && add.email}
              onChange={changeHandler}
              placeholder={t("email_placeholder")}
              className={submitted && add && !add.email ? " is-invalid" : ""}
            />
          </div>
          {submitted && add && !add.email && (
            <div className="invalid-feedback">{t("email_required")}</div>
          )}
          <div className="mob_number flt">
            <label>{t("message")}</label>
            <input
              name="reason"
              value={add && add.reason}
              onChange={changeHandler}
              className={submitted && add && !add.reason ? " is-invalid" : ""}
            />
          </div>
          {submitted && add && !add.reason && (
            <div className="invalid-feedback">{t("message_required")}</div>
          )}
          <div className="submit_btn flt">
            <button onClick={handleSubmit}>{t("submit")}</button>
          </div>
        </div>
      </div>
    );
  };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };

  return (
    <>
      {renderHeaderBar()}
      {renderBanner()}
      <div className="contact_main flt">
        <div className="container">
          <div className="breadcrumbs breadcrumbs1  flt">
            <span onClick={gotoHome}>HOME /</span>
            <span>Contact US</span>
          </div>
          <div className="row">
            {renderMap()}
            <div className="col-sm-1"></div>
            {renderContactmain()}
          </div>
        </div>
      </div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(Contact);
