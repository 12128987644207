import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ProductService } from "../../../services";

const useProductList = (location) => {

    const menudata = useSelector((state) => state?.menuData?.menu);
    let data = window.location.href.split("/")[4];
    let newsearch1 = data.replace(/[%20\r]/g, ' ');
    let newsearch = newsearch1.replace(/[ ]{3}/g, ' ');
    const [productList, setProductList] = useState([])
    const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
    const [pageCount, setPageCount] = useState(0);
    const [current, setcurrent] = useState(1);
    const [main, setMain] = useState(location?.state?.name ? [location?.state?.name] : []);
    const [sub, setSub] = useState(location?.state?.subdata ? [location?.state?.subdata] : []);
    const navigate = useNavigate();
    const [search, setSearch] = useState(newsearch ? newsearch : "");
    const [loaderdata, setLoaderdata] = useState("notset");

    useEffect(() => {
        setSearch(newsearch ? newsearch : "");
        setMain(location?.state?.name ? [location?.state?.name] : [])
        setSub(location?.state?.subdata ? [location?.state?.subdata] : []);
        setcurrent(1);
        // eslint-disable-next-line
    }, [location])
    /**
           *   api call for search product from menu
           */
    useEffect(() => {
        setLoaderdata("notset")
        ProductService.searchData(search, main, sub, current).then((response) => {

            if (response?.data?.status?.code === 0) {
                setProductList(response?.data?.data?.products?.data)
                setPageCount(response?.data?.data?.products?.last_page);
                setLoaderdata("isfull");
                if (response?.data?.data?.products?.last_page < response?.data?.data?.products?.current_page) {
                    setcurrent(1);
                }
            } else {
                setLoaderdata("isempty")
            }
        }).catch((err) => console.log(err))

        // eslint-disable-next-line
    }, [main, sub, search, current])

    //page count change
    const changeCurrentPage = (page) => {
        setcurrent(page.selected + 1);
    };

    //onChange event for checkbox value
    function handleChange(event) {
        if (event.target.name === "main") {
            // alert("hai")
            let updatedList = [...main];
            if (event.target.checked) {
                updatedList = [...main, event.target.value];
            } else {
                updatedList.splice(main.indexOf(event.target.value), 1);
            }
            setMain(updatedList);
            setSearch("");


        }
        //sub category
        if (event.target.name === "sub") {
            let updatedList = [...sub];
            if (event.target.checked) {
                updatedList = [...sub, event.target.value];
            } else {
                updatedList.splice(sub.indexOf(event.target.value), 1);
            }

            setSub(updatedList);
            setSearch("");
            // localStorage.setItem("sub", [updatedList]);
            // console.log(sub)
        }
    }

    /**
     * check for the given value is checked or not
     * @param {*} title 
     * @returns 
     */
    const isInMainCategory = (title) => {
        return main.includes(title);
    }

    const isInSubCategory = (title) => {
        return sub.includes(title);
    }
    /**
     * search field value 
     */
    function changeHandler(e) {
        setSearch(e.target.value);
        setcurrent(1)
    }
    /**
     * go to home
     */
    const gotoHome = () => {
        navigate("/");
    }
    /**
     * move sku to productdetails page
     */
    const viewDetail = (display) => {
        navigate(`/product-details/${display.SKU}`, {
            state: { id: display.SKU },
        });
    }

    return {
        menudata,
        productList,
        image_url,
        pageCount,
        current,
        search,
        loaderdata,
        changeHandler,
        changeCurrentPage,
        handleChange,
        isInMainCategory,
        isInSubCategory,
        gotoHome,
        viewDetail
    }
}
export default useProductList;