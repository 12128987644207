import FooterScreen from "../../_common/FooterScreen/FooterScreen"
import HeaderScreen from "../../_common/Header/HeaderScreen"
import useMyOrder from "./useMyOrder"
import "./MyOrder.css"
import { commaValidation } from "../../../utility";
import { loader, defaultimg, emptyCart } from "../../../assets/images";
import { withTranslation } from "react-i18next";

const MyOrder = (props: any) => {
    const { t } = props;
    const { myOrder, image_url, loaderdata, downloadInvoice, gotoHome } = useMyOrder();

    /**
   * render header
   */
    const renderHeaderBar = () => {
        return (
            <div>
                <HeaderScreen />
            </div>

        )
    }
    //loading image display 
    const loadingDisplay = () => {
        return (
            <div className="product_loader flt">
                <img src={loader} alt="populargold" />
            </div>
        );
    };
    //if cart is empty
    const cartEmpty = () => {
        return (
            <div className="cartEmpty flt">
                <img src={emptyCart} alt="no_text" />
                <div className="cartempty_txt flt">No Orders Found</div>
            </div>
        )
    }
    /**
     * 
     * @returns render order history
     */

    const renderOrderHistory = () => {
        return (
            <div className="main_order flt">

                <div className="container">
                    <div className="breadcrumbs breadcrumbs1  flt">
                        <span onClick={gotoHome}>{t("home")}</span> <span>{t("order_history")}</span>
                    </div>
                    <div className="cartData flt">{t("order_history")}</div>
                    <div className="row">
                        {myOrder && myOrder.map((resp: any) =>

                            <div className="col-sm-6">

                                <div className="mainord">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="order_img flt">
                                                <img src={resp?.pro_images?.[0]?.image ? image_url + resp?.pro_images?.[0]?.image : defaultimg} alt="noimage" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="product_info flt">
                                                <ul>
                                                    <li className="order_name flt">{resp?.product?.pro_details?.name}</li>
                                                    <li>{t("item_code")}:  {resp?.product?.SKU}</li>
                                                    <li>{t("Weight")} :{resp?.product?.pro_details?.weight} {t("gm")}</li>
                                                    <li className="price flt">{t("rs")} {commaValidation(resp?.product?.c_gross_value)}</li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="col-sm-3">
                                            <div className="invoice flt">
                                                <button onClick={() => downloadInvoice(resp?._id)}>{t("download")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>

            </div>
        )
    }

    /**
 * render footer
 */
    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <div className="flt">
            {loaderdata !== "isfull" && loadingDisplay()}
            {renderHeaderBar()}
            {myOrder && myOrder.length <= 0 ? cartEmpty() : renderOrderHistory()}
            {renderFooter()}
        </div>
    )
}
export default withTranslation()(MyOrder);