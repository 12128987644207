import { useEffect, useState } from "react";
import { ProductService } from "../../../services";


const useTermsCondition = () => {
    const [privacyData, setPrivacyData] = useState<any>("");
    const [loaderdata, setLoaderdata] = useState("notset");
    /**
     * privacy policy api call
     */
    useEffect(() => {
        ProductService.privacyPolicy().then((response) => {
            setLoaderdata("isfull");
            setPrivacyData(response)
        })
    }, [])
    return {
        privacyData,
        loaderdata
    }
}
export default useTermsCondition;