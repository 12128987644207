import { logo } from "../../../assets/images";
import useGetOtp from "./useGetOtp";
import "./GetOtp.css";
import { withTranslation } from "react-i18next";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";

const GetOtp = (props: any) => {
    const { t } = props;
    /**
     * useGetOtp hooks
     */
    const { handleSubmit,
        otp,
        new_password,
        submitted,
        re_password, handleOtpChange,
        handleRePasswordChange,
        handleNewPasswordChange } = useGetOtp(t);


    /**
 * 
 * @returns render header screen
 */
    const renderHeader = () => {
        return (
            <div className="main_login flt">
                <HeaderScreen />
            </div>
        );
    };

    /**
     * 
     * @returns render footer screen
     */
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    /**
     * 
     * @returns render getScreen otp
     */
    const renderGetOtpScreen = () => {
        return (
            <div className="login_main flt">
                <div className="login_form reset_form">
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="main_block">
                                <div className="welcome flt">
                                    {t("reset_password")}
                                </div>
                                <div className="mob_number flt">
                                    <label>{t("enter_otp")}</label>
                                    <input
                                        type="number"
                                        placeholder={t("enter_otp")}
                                        name="otp"
                                        value={otp}
                                        onChange={handleOtpChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSubmit(e);
                                            }
                                        }}
                                        onWheelCapture={(e: any) => {
                                            e.target.blur();
                                        }}
                                        className={submitted && !otp ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !otp && (
                                    <div className="invalid-feedback">{t("otp_required")}</div>
                                )}
                                <div className="mob_number flt">
                                    <label>{t("password")}</label>
                                    <input
                                        type="password"
                                        placeholder={t("password_placeholder")}
                                        name="new_password"
                                        value={new_password}
                                        onChange={handleNewPasswordChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSubmit(e);
                                            }
                                        }}
                                        className={submitted && !new_password ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !new_password && (
                                    <div className="invalid-feedback">{t("password_required")}</div>
                                )}

                                <div className="mob_number flt">
                                    <label>{t("reset_password")}</label>
                                    <input
                                        type="password"
                                        placeholder={t("cpassword")}
                                        name="re_password"
                                        value={re_password}
                                        onChange={handleRePasswordChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSubmit(e);
                                            }
                                        }}
                                        className={submitted && !re_password ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !re_password && (
                                    <div className="invalid-feedback">
                                        {t("cpassword_required")}
                                    </div>
                                )}


                                <div className="login_btn flt">
                                    <button className="login" onClick={handleSubmit}>{t("reset_password")}</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="image_sub flt">
                                <img src={logo} alt="pop_background" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderHeader()}
            {renderGetOtpScreen()}
            {renderFooter()}
        </>
    )
}
export default withTranslation()(GetOtp);