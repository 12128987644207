import { useLocation } from "react-router";
import { commaValidation } from "../../../utility";
import FooterScreen from "../../_common/FooterScreen/FooterScreen"
import HeaderScreen from "../../_common/Header/HeaderScreen"
import { useRazorpay } from "../RazorPay/useRazorPay";
import "./Checkout.css";
import useCheckout from "./useCheckout";
import { withTranslation } from "react-i18next";
const Checkout = (props: any) => {
    const { t } = props;
    const location: any = useLocation();
    let { displayRazorpay } = useRazorpay();
    /**
     * useCheckout hook
     */
    const {
        name,
        mobile,
        alternateMobile,
        address,
        locality,
        city,
        state,
        landmarkValue,
        pincode,
        district,
        submitted,
        changeHandler,
        storeState,
        storeDistrict,
        handleSubmit
    } = useCheckout(location, displayRazorpay, t);

    //render header
    const renderHeaderBar = () => {
        return (
            <div>
                <HeaderScreen />
            </div>

        )
    }

    //render checkout screen
    const renderCheckoutScreen = () => {
        return (
            <div className="shipping_container flt">
                <div className="shipping_head flt">{t("add_delivary_address")}</div>
                <div className="shipping_cont flt">
                    <div className="row shipping_fields">
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("name")}</label>
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    name="name"
                                    value={name}
                                    onChange={changeHandler}
                                    className={submitted && !name ? " is-invalid" : ""}
                                />
                                {submitted && !name && (
                                    <div className="invalid-feedback">{t("name_required")}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("Mobile Number")}</label>
                                <input
                                    type="number"
                                    placeholder={t("mobile_placeholder")}
                                    name="mobile"
                                    value={mobile}
                                    onChange={changeHandler}
                                    className={submitted && !mobile ? " is-invalid" : ""}
                                />
                                {submitted && !mobile && (
                                    <div className="invalid-feedback">
                                        {t("mobile_required")}
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("alt_mob")}</label>
                                <input
                                    type="number"
                                    placeholder={t("alt_placeholder")}
                                    name="alternateMobile"
                                    value={alternateMobile}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("address")}</label>
                                <input
                                    type="text"
                                    placeholder="Enter Address"
                                    name="address"
                                    value={address}
                                    onChange={changeHandler}
                                    className={
                                        submitted && !address ? " is-invalid" : ""
                                    }
                                />
                                {submitted && !address && (
                                    <div className="invalid-feedback">
                                        {t("address_required")}
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("locality")}</label>
                                <input
                                    type="text"
                                    placeholder={t("enter_locality")}
                                    name="locality"
                                    value={locality}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <label>{t("select_state")}</label>
                            <div className="form-group flt">
                                <select
                                    name="state"
                                    // value={billing_state}
                                    className={
                                        submitted && !state ? " is-invalid" : ""
                                    }
                                    onChange={changeHandler}
                                >
                                    <option>{t("select_state")}</option>
                                    {storeState &&
                                        storeState.map((state: any) => (
                                            <option value={JSON.stringify(state)} key={state?._id}>{state?.state}</option>
                                        ))}
                                </select>
                                {submitted && !state && (
                                    <div className="invalid-feedback">{t("state_is_required")}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <label>{t("select_district")}</label>
                            <div className="form-group flt">
                                <select
                                    name="district"
                                    // value={billing_district}
                                    onChange={changeHandler}
                                    className={
                                        submitted && !district ? " is-invalid" : ""
                                    }
                                >
                                    <option>{t("select_district")}</option>
                                    {storeDistrict &&
                                        storeDistrict.map((district: any) => (
                                            <option value={JSON.stringify(district)} key={district._id}>{district.district}</option>
                                        ))}
                                </select>
                                {submitted && !district && (
                                    <div className="invalid-feedback">
                                        {t("district_required")}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("city")}</label>
                                <input
                                    type="text"
                                    placeholder="Enter City"
                                    name="city"
                                    value={city}
                                    onChange={changeHandler}
                                    className={
                                        submitted && !city ? " is-invalid" : ""
                                    }
                                />
                                {submitted && !city && (
                                    <div className="invalid-feedback">{t("city_is_required")}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("land_mark")}</label>
                                <input
                                    type="text"
                                    placeholder={t("landmark_placeholder")}
                                    name="landmarkValue"
                                    value={landmarkValue}
                                    onChange={changeHandler}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>{t("Pincode")}</label>
                                <input
                                    type="number"
                                    placeholder={t("pincode_placeholder")}
                                    name="pincode"
                                    value={pincode}
                                    onChange={changeHandler}
                                    className={
                                        submitted && !pincode ? " is-invalid" : ""
                                    }
                                />
                                {submitted && !pincode && (
                                    <div className="invalid-feedback">
                                        {t("Pincode_is_required")}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className="form-group flt">
                                <label>Delivery Charge</label>
                                <input type="text" />
                            </div>
                        </div> */}
                        <div className="col-sm-8"></div>

                        <div className="col-sm-12">

                            <ul className="shipping_list flt">
                                <li>{t("totalitem")} <span>{location?.state?.qty}</span></li>
                                {/* <li>Discount<span>-<b>₹</b>2,29,3.73</span></li> */}
                                {/* <li>Delivery charges<span><b>₹</b>500.00</span></li> */}
                                <li>{t("gst_3")}<span><b>₹</b>{location?.state?.gstAmount}</span></li>
                                <li>{t("tot_amount")}<span><b>₹ </b>{commaValidation(location?.state?.total_amount)}</span></li>
                            </ul>

                            <button className="shipping_btn flt" onClick={handleSubmit}>
                                {t("checkout")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <>
            {renderHeaderBar()}
            {renderCheckoutScreen()}
            {renderFooter()}
        </>
    )
}
export default withTranslation()(Checkout);