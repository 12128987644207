import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions, registration } from "../../../actions";
import { UserService } from "../../../services";

const useRegistration = (t: any) => {
    const [name, setName] = useState("");
    const [mobile_no, setMobileNo] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [re_password, setRePassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState("");
    const [registerData, setRegisterData] = useState<any>({});
    const dispatch: any = useDispatch();
    const history = useNavigate();

    //useEffect to check response
    useEffect(() => {
        if (registerData?.data?.status?.code === 0) {
            setName("");
            setMobileNo("");
            setEmail("");
            setPassword("");
            setRePassword("");
            history("/");
            setSubmitted(false);
            dispatch(alertActions.success(registerData?.data?.status?.message));
        } else {
            dispatch(alertActions.error(registerData?.data?.status?.message));
        }
        // eslint-disable-next-line 
    }, [registerData]);


    //onchange handler for storing value
    const changeHandler = (e: any) => {
        if (e.target.name === "name") {
            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                setName(e.target.value);
            } else if (!e.target.value) {
                setName("");
            }
        }
        if (e.target.name === "mobile_no") {
            setMobileNo(e.target.value)
        }
        if (e.target.name === "email") {
            setEmail(e.target.value)
        }
        if (e.target.name === "password") {
            setPassword(e.target.value)
        }
        if (e.target.name === "re_password") {
            setRePassword(e.target.value)
        }

    }


    //on otp submit
    const handleOtpSubmit = (e: any) => {
        e.preventDefault();
        setSubmitted(true);

        var filter = /^[0-9]{10}$/;
        var pattern =
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
        if (name && mobile_no && email && password) {
            if (email && !pattern.test(email)) {
                dispatch(alertActions.error("Enter valid Email Address"));
            } else if (mobile_no && !filter.test(mobile_no)) {
                dispatch(alertActions.error("Enter valid Mobile Number"));
            } else if (password !== re_password) {
                dispatch(alertActions.error("Both Passwords must match"));
            } else {
                UserService.verifyEmailMobile(mobile_no, email)
                    .then((response) => {
                        if (response?.data?.status?.code === 0) {
                            UserService.userOtp(mobile_no, email)
                                .then((response) => {
                                    if (response?.data?.status?.code === 0) {
                                        setShowOtp(true);
                                    } else {
                                        dispatch(
                                            alertActions.error(response?.data?.status?.message)
                                        );
                                    }
                                })
                                .catch((error) => {
                                    console.log("Errorreg", error);
                                });
                        } else {
                            dispatch(alertActions.error(response?.data?.status?.message));
                        }
                    })
                    .catch((error) => {
                        console.log("Errorreg", error);
                    });
            }
        }
    }

    //otp view display of otp box
    const openOtp = () => {
        setShowOtp(true);
    };

    //close otp box on state change
    const closeOtp = () => {
        setShowOtp(false);
    };

    //store otp to send via api
    function changeOtpHandler(e: any) {
        setOtp(e.target.value);
    }

    /**
     *  api call for verify otp
     * @param {} e 
     */
    function verifyOtp(e: any) {
        // console.log("otp", e);
        e.preventDefault();
        setSubmitted(true);
        if (otp) {
            UserService.verifyOtp(otp, mobile_no)
                .then((response) => {
                    if (response?.data?.status?.code === 0) {
                        handleSubmit(e);
                        dispatch(alertActions.success(response?.data?.status?.message));
                    } else {
                        setShowOtp(false);
                        setOtp("");
                        openOtp();
                        dispatch(alertActions.error(response?.data?.status?.message));
                    }
                })
                .catch((error) => {
                    console.log("Errorreg", error);
                    //   setLoading(false);
                });
        }
    }

    /**
     * submit on button click
     * @param {*} e 
     */
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmitted(true);
        var filter = /^[0-9]{10}$/;
        var pattern =
            /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (name && mobile_no && email && password) {
            if (email && !pattern.test(email)) {
                dispatch(alertActions.error(t("valid_email")));
                // setTimeout(() => dispatch(alertActions.clear()), 3000);
            } else if (mobile_no && !filter.test(mobile_no)) {
                dispatch(alertActions.error(t("enter_valid_mobile")));
                // setTimeout(() => dispatch(alertActions.clear()), 3000);
            } else if (password !== re_password) {
                dispatch(alertActions.error(t("password_match")));
                // setTimeout(() => dispatch(alertActions.clear()), 3000);
            } else {
                dispatch(registration(name, mobile_no, email, password))
                    .then((response: any) => {
                        setRegisterData(response);
                        //  window.location.reload();
                    })
                    .catch((error: any) => {
                        console.log("Errorreg", error);
                        //   setLoading(false);
                    });
            }
        }
    }

    /**
     * redirect to login
     */
    const redirectLogin = () => {
        history("/Login")
    }

    return {
        name,
        email,
        mobile_no,
        password,
        re_password,
        submitted,
        otp,
        showOtp,
        setOtp,
        verifyOtp,
        changeHandler,
        handleOtpSubmit,
        closeOtp,
        openOtp,
        changeOtpHandler,
        handleSubmit,
        redirectLogin
    }
}
export default useRegistration;