import useProductDetails from "./useProductDetails";
import "./ProductDetails.css";
import { defaultimg, loader, close_pop } from "../../../assets/images";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import { commaValidation } from "../../../utility";
import { withTranslation } from "react-i18next";

const ProductDetails = (props: any) => {
    const { t } = props;
    const {
        productDetails,
        image_url,
        loaderdata,
        showOtp,
        openOtp,
        addCart,
        changeImage,
        closeOtp
    } = useProductDetails();
    //render header
    const renderHeaderBar = () => {
        return (
            <div>
                <HeaderScreen />
            </div>

        )
    }
    /**
     * 
     * @returns breadcrumbs
     */
    const renderBreadcrumbs = () => {
        return (
            <div className="breadcrumbs breadcrumbs1  flt">
                HOME / <span>{productDetails?.name}</span>
            </div>
        )
    }
    //render image
    const renderImageSection = () => {
        return (
            <div className="col-sm-6">
                <div className="detail_left flt" onClick={openOtp}>
                    <img src={productDetails?.pro_image?.image ? image_url + productDetails?.pro_image?.image : defaultimg} className="example" alt="noimage" />
                </div>
            </div>
        )
    }

    //loading image display 
    const loadingDisplay = () => {
        return (
            <div className="cartpg flt">
                <img src={loader} alt="abharan" />
            </div>
        );
    };

    /**
     * 
     * @returns 
     */
    const OtpVerify = () => {
        return (
            <div className="modalPop" id="enquiryModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" onClick={closeOtp}>
                                <img src={close_pop} alt="no_image" />
                            </button>
                            {/* <div className="enquiry_head flt">{t("verify_otp")}</div> */}
                            <div className="enquiry_cont flt">

                                <img src={productDetails?.pro_image?.image ? image_url + productDetails?.pro_image?.image : defaultimg} className="example" alt="noimage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    //render details section
    const renderDetails = () => {
        return (
            <div className="col-sm-6">

                <div className="detail_right flt">
                    <div className="detail_name flt">{productDetails?.name}</div>

                    <div className="detail_rate flt"><span><b>{t("rs")} </b>{commaValidation(productDetails?.c_gross_value && productDetails?.c_gross_value !== "NaN" ? productDetails?.c_gross_value : 0)}</span></div>
                    <div className="detail_code flt">{t("item_code")} : {productDetails?.SKU}</div>
                    <div className="detail_btn flt">
                        <button onClick={() => addCart(productDetails)}>{t("add_to_cart")}</button>

                    </div>
                    <div className="overprice flt">
                        <div className="price_breakup flt">{t("price_breakup")}</div>
                        <ul className="detail_note flt">
                            <li>
                                {t("metal_cost")}
                            </li>
                            <li>
                                {t("rs")}  {commaValidation(productDetails?.c_metal_value)}
                            </li>
                            <li>
                                {t("making_charge")}
                            </li>
                            <li>
                                {t("rs")}  {commaValidation(productDetails?.c_making_amount)}
                            </li>
                            <li>
                                {t("gst")}
                            </li>
                            <li>
                                {t("rs")}   {commaValidation(productDetails?.c_gst_amount)}
                            </li>
                            <li className="tot_colour">
                                {t("total")}
                            </li>
                            <li className="tot_colour">
                                {t("rs")}   {commaValidation(productDetails?.c_gross_value)}
                            </li>
                        </ul>
                        <div className="price_breakup flt">{t("product_details")}</div>
                        <ul className="detail_note flt">
                            <li>
                                {t("gross")}
                            </li>
                            <li>
                                {productDetails?.net_weight} {t("gm")}
                            </li>
                            <li>
                                {t("stoneweight")}
                            </li>
                            <li>
                                {productDetails?.pro_details?.stone_weight} {t("gm")}
                            </li>
                            <li>
                                {t("stonecost")}
                            </li>
                            <li>
                                {t("rs")}  {productDetails?.pro_details?.stone_value}
                            </li>
                            <li>
                                {t("MetalWeight")}
                            </li>
                            <li>
                                {productDetails?.pro_details?.weight} {t("gm")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    //render productYou may like
    const youMayLike = () => {
        return (
            <div className="related_main flt">
                <div className="related_head flt">{t("same_collection")}</div>
                <div className="related_cont flt">
                    <div className="row items" id="style-3">
                        {productDetails && productDetails?.likely && productDetails?.likely.map((likelydata: any) =>
                            <div className="col-sm-3">
                                <div className="related_box flt">
                                    <div className="related_image flt" onClick={() => changeImage(likelydata)}>
                                        <img src={likelydata?.pro_image?.image ? image_url + likelydata?.pro_image?.image : defaultimg} alt="noimage" />

                                    </div>
                                    <div className="related_txt flt">
                                        <div className="related_desc flt">
                                            <div className="related_name">{likelydata?.name}</div>
                                            <div className="related_rate">₹ <span>{commaValidation(likelydata?.total_price)}</span></div>
                                        </div>
                                        <div className="related_weight flt">{likelydata?.net_weight ? likelydata?.net_weight : 0} GM</div>
                                    </div>
                                </div>
                            </div>
                        )}



                    </div>
                </div>
            </div>
        )
    }
    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <>
            {loaderdata !== "isfull" && loadingDisplay()}
            {renderHeaderBar()}
            <div className="detail_container flt">

                <div className="container">
                    {renderBreadcrumbs()}
                    <div className="row">
                        {renderImageSection()}
                        {renderDetails()}
                    </div>
                    <div className="divider_main flt">
                        <div className="divider flt"></div>
                    </div>
                </div>
                {youMayLike()}
            </div>
            {showOtp && OtpVerify()}
            {renderFooter()}
        </>
    )
}
export default withTranslation()(ProductDetails);