import { ProductService, UserService } from "../services";
import { SAVE_USER_DATA, SESSION_EXPIRE, USER_LOGOUT, EDIT_USER_DATA } from "./types";


export const userLogin = (mobile_no, password) => (dispatch) => {
    return UserService.login(mobile_no, password).then((data) => {
        if (data.data.status.code === 0) {
            dispatch({
                type: SAVE_USER_DATA,
                payload: {
                    token: data.data.data.access_token,
                    user: data.data.user,
                    type: data.data.data.token_type,
                },
            });
        }
        return Promise.resolve(data);
    });
};

export const registration =
    (name, mobile_no, email, password) => (dispatch) => {
        return UserService.registration(name, mobile_no, email, password).then(
            (data) => {
                if (data.data.status.code === 0) {
                    dispatch({
                        type: SAVE_USER_DATA,
                        payload: {
                            token: data.data.data.access_token,
                            user: data.data.user,
                            type: data.data.data.token_type,
                        },
                    });
                }
                return Promise.resolve(data);
            }
        );
    };

export function userLogout() {
    return function (dispatch) {
        const action = {
            type: USER_LOGOUT,
        };
        localStorage.clear();


        dispatch(action);
    };
}
export const sessionExpire = (dispatch) => {
    dispatch({
        type: SESSION_EXPIRE,
        // payload: {
        //   data: true,
        // },
    });
    return;
};
export const myDetails =
    (
        first_name,
        email,
        address,
        mobile_no,
        newpassword,
        current_password,
        user_id
    ) =>
        (dispatch) => {
            return ProductService.myDetails(
                first_name,
                email,
                mobile_no,
                address,
                newpassword,
                current_password,
                user_id
            ).then((data) => {
                if (data.data.status.code === 0) {
                    dispatch({
                        type: EDIT_USER_DATA,
                        payload: {
                            // token: data.data.data.access_token,
                            user: data?.data?.data?.user_details,
                            // type: data.data.data.token_type,
                        },
                    });
                }
                return Promise.resolve(data);
            });
        };

