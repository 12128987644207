import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router"
import { alertActions } from "../../../actions";
import { userLogout } from "../../../actions/UserAction";
import { ProductService } from "../../../services";

const useMenu = () => {
    const navigate = useNavigate();
    const user_id: string = useSelector((state: RootStateOrAny) => state?.userData?.token)
    const menudata: any = useSelector((state: RootStateOrAny) => state?.menuData?.menu);
    const [cartCount, setCartCount] = useState<any>("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (user_id) {
            ProductService.getCart().then((response) => {

                if (response?.data?.status?.code === 0) {
                    setCartCount(response?.data?.data?.cart_count)
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    const privacy_notice = () => {
        navigate("/privacypolicy")
    }
    const terms_condition = () => {
        navigate("/terms-conditions")
    }
    const cancellation_refund = () => {
        navigate("/cancellation-refund")
    }
    const login_screen = () => {
        navigate("/Login")
    }
    const handleLogout = () => {
        dispatch(userLogout());
        navigate("/Login");
        dispatch(alertActions.success("Logout Success"));
    };
    const getMenuId = (data: string) => {
        navigate(`/product-list/${data}`, {
            state: { name: data },
        });
    }
    //go to home
    const gotoHome = () => {
        navigate("/")
    }
    //move to cart if auth
    const moveCart = () => {
        if (user_id) {
            navigate("/cart");
        } else {
            navigate("/Login")
        }
    }
    //redirect to about us
    const aboutUs = () => {
        navigate("/about")
    }
    //redirect to contact
    const contactUs = () => {
        navigate("/contact");
    }
    //redirect ro my account
    const myAccount = () => {

        if (user_id) {
            navigate("/myAccount");
        } else {
            navigate("/Login")
        }
    }
    /**
     * get my order details
     */
    const myOrder = () => {
        /**
         * if user exist then only move to my order otherwise move to login screen
         */
        if (user_id) {
            navigate("/myorders");
        } else {
            navigate("/Login")
        }
    }

    return {
        login_screen,
        handleLogout,
        user_id,
        menudata,
        getMenuId,
        gotoHome,
        moveCart,
        cartCount,
        aboutUs,
        contactUs,
        privacy_notice,
        terms_condition,
        cancellation_refund,
        myAccount,
        myOrder
    }
}
export default useMenu;