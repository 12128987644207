import { HeaderService } from "../services/HeaderService";
import { SAVE_HEADER_DATA } from "./types";


export const header = () => (dispatch) => {
  return HeaderService.header().then((data) => {
    if (data?.data?.status?.code === 0) {
      dispatch({
        type: SAVE_HEADER_DATA,
        payload: { menu: data?.data?.data },
      });
    }
    return Promise.resolve(data);
  });
};
