import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./Home.css";
import {
  banner,
  banner1,
  alphabet,
  alpha2,
  white_ring,
  gold_ring,
  bracelet,
  neck,
  earings,
  necklace,
  rings,
  bang,
} from "../../../assets/images";
import useHome from "./useHome";
import Slider from "react-slick";

const Home = () => {
  const { menudata, image_url, getNewId, getGoldDetails } = useHome();

  var catregorySlider = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2500,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen />
      </div>
    );
  };

  //render banner section
  const renderBanner = () => {
    return (
      <div className="banner_container flt">
        {/* <Slider autoplaySpeed={5000} speed={1500} infinite={true} arrows={false} dots={false} autoplay={true} slidesToShow={1} slidesToScroll={1} className="mobile_hide flt" >
          {menudata &&
            menudata?.banners &&
            menudata?.banners !== null
            ? menudata?.banners.map((banner_list, index) => (

              <div key={index}>
                <a href={banner_list?.link}>
                  < img
                    src={image_url + banner_list?.image}
                    alt="no_image"
                    key={banner_list?._id}
                 
                  />
                </a>
              </div>
            ))
            : null
          }
        </Slider> */}
        <img src={banner} alt="no_image" />
      </div>
    );
  };
  //render gold section
  const renderGold = () => {
    return (
      <div className="gold_main flt">
        <div className="container">
          <div className="gold_heading flt">
            <span>Gold Collections</span>
            <div className="gold_silver flt">
              Such a splendorous soul you are to be blessed with the majestic
              grandeur of gold and silver
            </div>
          </div>
          {renderImage()}
          {renderViewAll()}
          {renderSilver()}
          {renderSilverBanner()}
        </div>
      </div>
    );
  };
  //render gold image
  const renderImage = () => {
    return (
      <div className="gold_image flt">
        <div className="row">
          <div className="col-sm-4">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Gold", "Necklace")}
            >
              <img src={necklace} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Necklace</div>
          </div>
          <div
            className="col-sm-4"
            onClick={() => getGoldDetails("Gold", "Ear Ring")}
          >
            <div className="display_gold flt">
              <img src={earings} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Earings</div>
          </div>
          <div className="col-sm-4">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Gold", "bracelet")}
            >
              <img src={bracelet} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Bracelet</div>
          </div>
          <div className="col-sm-4">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Gold", "Bangles")}
            >
              <img src={bang} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Bangle</div>
          </div>
          <div className="col-sm-4">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Gold", "Finger ring")}
            >
              <img src={rings} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Finger Rings</div>
          </div>
          <div className="col-sm-4">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Gold", "Chain")}
            >
              <img src={neck} alt="no_image" />
            </div>
            <div className="gold_name flt">Gold Chain</div>
          </div>
        </div>
      </div>
    );
  };
  const renderViewAll = () => {
    return (
      <div className="viewall_main flt">
        <div className="view_banner flt">
          <img onClick={() => getNewId("gold")} src={banner1} alt="no_image" />
        </div>
      </div>
    );
  };
  //render silver section
  const renderSilver = () => {
    return (
      <div className="gold_main flt">
        <div className="container">
          <div className="gold_heading flt">
            <span>Silver Collection</span>
            <div className="gold_silver flt">
              Your priceless dreams shall glisten like the abiding glint of gold
              looting all the reverent attentions.
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderSilverBanner = () => {
    return (
      <div className="gold_image flt">
        <div className="row">
          <div className="col-sm-6">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Silver", "Pendant")}
            >
              <img src={alphabet} alt="no_image" />
            </div>
            <div className="gold_name flt">Alphabet Pendants</div>
          </div>
          <div className="col-sm-6">
            <div
              className="display_gold flt"
              onClick={() => getGoldDetails("Silver", "Ring")}
            >
              <img src={alpha2} alt="no_image" />
            </div>
            <div className="gold_name flt">Name Ring</div>
          </div>
        </div>
      </div>
    );
  };
  //render platinum section
  const renderRing = () => {
    return (
      <div className="ring_main flt">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="ring_box flt">
                <div className="ring_box_img flt">
                  <img src={white_ring} alt="no_img" />
                </div>
                <div className="ring_box_text flt">
                  <div className="main_text flt">
                    Platinum Couple Rings & Bands
                  </div>
                  <div className="sub_text flt">
                    Cherish your affinity by binding the souls together with the
                    platinum yarn of love.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="ring_box flt">
                <div className="ring_box_text flt">
                  <div className="main_text flt">Birthstone</div>
                  <div className="sub_text flt">
                    Endear all the adorable moments of your life by exchanging
                    the probity of platinum benediction.
                  </div>
                </div>
                <div className="ring_box_img flt">
                  <img src={gold_ring} alt="no_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  //render new arrivals
  const newArrivals = () => {
    return (
      <div className="gold_main flt">
        <div className="container">
          <div className="gold_heading flt">
            <span>New Arrivals</span>
            <div className="gold_silver flt">Explore POPULAR GOLD Designs</div>
          </div>
          {newArrivalImage()}
        </div>
      </div>
    );
  };
  //render new arrival image
  const newArrivalImage = () => {
    return (
      <div className="gold_image looknew flt">
        <div className="row">
          {/* {menudata && menudata.whatsnew !== null && menudata.whatsnew !== null ? menudata.whatsnew.map((whats_list) => (
            <div className="col-sm-3" key={whats_list?._id} onClick={() => getNewId(whats_list?.title)}>
              <div className="display_gold flt">
                <img src={image_url + whats_list?.images} alt="no_image" />
              </div>
              <div className="gold_name flt">
                {whats_list.title}
              </div>
            </div>)) : null} */}

          <div className="catregory_cont flt">
            <Slider {...catregorySlider}>
              {menudata &&
              menudata.whatsnew !== null &&
              menudata.whatsnew !== null
                ? menudata?.whatsnew?.map((category_list: any) => (
                    <div className="category_box flt" key={category_list?._id}>
                      <span onClick={() => getNewId(category_list?.title)}>
                        <div className="category_image flt">
                          <img
                            src={image_url + category_list?.images}
                            alt="noimage"
                          />
                        </div>
                        <div className="category_name flt">
                          {category_list?.title}
                        </div>
                      </span>
                    </div>
                  ))
                : null}
            </Slider>
          </div>
        </div>
      </div>
    );
  };
  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };

  return (
    <div className="main_container">
      {renderHeaderBar()}
      {renderBanner()}
      {renderGold()}
      {renderRing()}
      {newArrivals()}
      {renderFooter()}
    </div>
  );
};

export default Home;
