import "./Footer.css";
import { logo } from "../../../assets/images";
import useMenu from "../Menu/useMenu";
import { whatsapp } from "../../../assets/images";
import { withTranslation } from "react-i18next";
const FooterScreen = (props: any) => {
  /**
   * localization implemented for multiple languiage support
   */
  const { t } = props;

  /**
   * useMenu hook for business logics
   */
  const {
    aboutUs,
    contactUs,
    getMenuId,
    privacy_notice,
    terms_condition,
    cancellation_refund,
    menudata,
  } = useMenu();
  /**
   *
   * @returns render footer screen
   */
  const renderMainFooter = () => {
    return (
      <div className="footer_main flt">
        <div className="container">
          <div className="whatsapp_icon">
            <a
              href="https://api.whatsapp.com/send?phone=9035197583"
              target="_blank"
              rel="noreferrer"
            >
              <img className="img-responsive" src={whatsapp} alt="pop_gold" />
            </a>
          </div>
          <div className="footer_box flt">
            <div className="footer_img">
              <img src={logo} alt="no_img" />
            </div>
            <div className="gold_mart flt">{t("gold_mart")}</div>
            <div
              className="mart_subtext flt"
              dangerouslySetInnerHTML={{ __html: `${t("mart_sub")}` }}
            ></div>
            {/* <div className="mart_subtext flt">{t("mart_sub")}</div> */}
            <div className="menu_display flt">
              <ul className="menu_sub flt">
                <li onClick={aboutUs}>{t("about_us")}</li>
                {menudata &&
                  menudata?.category &&
                  menudata?.category.map((data: any) => (
                    <li onClick={() => getMenuId(data?.title)}>
                      {data?.title}
                    </li>
                  ))}

                <li onClick={contactUs}>{t("contact_us")}</li>
              </ul>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ul className="menu_sub1 flt">
                  <li>
                    <a href="mailto:admin@populargold.in" target="blank">
                      {t("contact_mail")}
                    </a>
                  </li>
                  <li>{t("ph_no")}</li>
                  <li>
                    <a
                      href="http://facebook.com/populargoldmart"
                      target="blank"
                    >
                      {t("facebook")}
                    </a>
                  </li>

                  <li className="filler"></li>

                  <li>{t("all_rights_reserved")}</li>
                  <li onClick={privacy_notice}>{t("privacy_notice")}</li>
                  <li onClick={terms_condition}>{t("terms_condition")}</li>
                  <li onClick={cancellation_refund}>{t("refund")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <>{renderMainFooter()}</>;
};
export default withTranslation()(FooterScreen);
