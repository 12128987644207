import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";

const useGetOtp = (t:any) => {
    const location:any = useLocation();
    const [otp, setOtp] = useState("");
    const [new_password, setNew_password] = useState("");
    const [mobile, setMobile] = useState(location?.state?.mobile);
    const [submitted, setSubmitted] = useState(false);
    const [re_password, setRe_password] = useState("");
    const [getOtpData, setGetOtpData] = useState<any>({});
    const dispatch = useDispatch();
    const history = useNavigate();

    useEffect(() => {
        if (getOtpData?.data?.status?.code === 0) {
            setOtp("");
            setNew_password("");
            setRe_password("");
            setMobile("");
            history("/login");
            setSubmitted(false);
            setTimeout(() => {
                dispatch(alertActions.success(getOtpData?.data?.status?.message));
            }, 50);
        } else {
            dispatch(alertActions.error(getOtpData?.data?.status?.message));
        }
        // eslint-disable-next-line 
    }, [getOtpData]);

    /**
     * send otp mobile number and password
     * @param {} e 
     */
    function handleSubmit(e:any) {
        e.preventDefault();
        setSubmitted(true);
        if (otp && new_password && re_password) {
            if (otp && otp.length !== 5) {
                dispatch(alertActions.error(t("invalid_otp")));
            } else if (new_password !== re_password) {
                dispatch(alertActions.error(t("password_match")));
            } else {
                UserService.getOtp(otp, new_password, mobile)
                    .then((response) => {
                        setGetOtpData(response);
                    })
                    .catch((error) => {
                        console.log("Errorreg", error);
                    });
            }
        }
    }
    /**
     * handle otp
     * @param {change} e 
     */
    function handleOtpChange(e:any) {
        setOtp(e.target.value);
    }
    /**
     * handle password change
     * @param {} e 
     */
    function handleRePasswordChange(e:any) {
        setRe_password(e.target.value);
    }
    /**
     * handle new password
     * @param {} e 
     */
    function handleNewPasswordChange(e:any) {
        setNew_password(e.target.value);
    }
    return {
        otp,
        new_password,
        re_password,
        submitted,
        handleSubmit,
        handleOtpChange,
        handleRePasswordChange,
        handleNewPasswordChange
    }
}
export default useGetOtp;