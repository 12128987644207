import "./headerScreen.css";
import Menu from "../Menu/Menu";
import { withTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const HeaderScreen = (props: any) => {
  /**
   * localisation implementations
   */
  const { t } = props;
  /**
   * 
   * @returns render marque tag as per design
   */
  const renderHeader = () => {
    return (
      <div className="flt">
        <ul className="middlebar flt hide_mobile">
          <li>
            {t("Phone_Number")} <a href="tel:08242425977" rel="noreferrer" target="_blank">{t("cont_no")}</a>
          </li>
          <li>{t("ofc_hrs")}</li>
          <li> {t("cust_care")}</li>
          <li>{t("time")}</li>
          <li className="filler"></li>
          <li>{t("mail_us")}</li>

        </ul>
        <div className="hide_desktop flt">
          <Marquee className="middlebar flt">
            <li>
              {t("Phone_Number")} <a href="tel:08242425977" rel="noreferrer" target="_blank">{t("cont_no")}</a>
            </li>
            <li>{t("ofc_hrs")}</li>
            <li> {t("cust_care")}</li>
            <li>{t("time")}</li>
            <li className="filler"></li>
            <li>{t("mail_us")}</li>

          </Marquee>
        </div>
      </div>
    )
  }
  /**
   * 
   * @returns render menu
   */
  const renderMenu = () => {
    return (
      <Menu />
    )
  }

  return (
    <div>
      {renderHeader()}
      {renderMenu()}
    </div>
  );
}
export default withTranslation()(HeaderScreen);
