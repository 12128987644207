import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions, userLogin } from "../../../actions";


const useLoginDetails = (t: any) => {
    const [mobile_no, setMobileNo] = useState("");
    const [password, setPassword] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [userData, setUserData] = useState<any>({});
    const dispatch: any = useDispatch();
    const navigate = useNavigate()

    //useEffrect for login details
    useEffect(() => {
        if (userData?.data?.status?.code === 0) {
            setMobileNo("");
            setPassword("");
            navigate("/");
            setSubmitted(false);
            dispatch(alertActions.success(userData?.data?.status?.message));
        } else {
            dispatch(alertActions.error(userData?.data?.status?.message));
        }
        // eslint-disable-next-line 
    }, [userData]);
    /**
     * check for input value and set state
     * @param {*} e 
     */
    const handleChange = (e: any) => {
        if (e.target.name === "mobile_no") {
            setMobileNo(e.target.value)
        }
        if (e.target.name === "password") {
            setPassword(e.target.value)
        }

    }
    /**
     * redirect tio registrationscreen
     */
    const registerRedirect = () => {
        navigate("/registration")
    }
    //on form submit
    function handleSubmit(e: any) {
        e.preventDefault();
        setSubmitted(true);
        var filter = /^[0-9]{10}$/;
        if (mobile_no && password) {
            if (mobile_no && !filter.test(mobile_no)) {
                dispatch(alertActions.error(t("enter_valid_mobile")));
            } else {
                dispatch(userLogin(mobile_no, password))
                    .then((response: any) => {
                        setUserData(response);
                    })
                    .catch((error: any) => {
                        console.log("Errorreg", error);
                    });
            }
        }
    }
    /**
     * forgot password
     */
    const forgotPassword = () => {
        navigate("/forgotPassword")
    }

    return {
        mobile_no,
        password,
        submitted,
        handleChange,
        handleSubmit,
        registerRedirect,
        forgotPassword

    }
}
export default useLoginDetails;

