import { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { myDetails } from "../../../actions/UserAction";

const useMyAccount = () => {

    const user_id = useSelector((state: RootStateOrAny) => state?.userData?.user);
    const [name, setName] = useState(user_id?.first_name ? user_id?.first_name : "");
    const [mobile_no, setMobileNo] = useState(user_id?.mobile_no ? user_id?.mobile_no : "");
    const [email, setEmail] = useState(user_id?.email ? user_id?.email : "");
    const [newpassword, setnewPassword] = useState("");
    const [current_password, setCurrentPassword] = useState("");
    const [address, setAddress] = useState(user_id?.shipping_address ? user_id?.shipping_address : "");
    const [submitted, setSubmitted] = useState(false);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    /**
     * based on input name set its value to state
     * @param {*} e 
     */
    const changeHandler = (e: any) => {
        if (e.target.name === "name") {
            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                setName(e.target.value);
            } else if (!e.target.value) {
                setName("");
            }
        }
        if (e.target.name === "mobile_no") {
            setMobileNo(e.target.value);
        }
        if (e.target.name === "email") {
            setEmail(e.target.value);
        }
        if (e.target.name === "address") {
            setAddress(e.target.value);
        }
        if (e.target.name === "new_password") {
            setnewPassword(e.target.value);
        }
        if (e.target.name === "current_password") {
            setCurrentPassword(e.target.value);
        }

    }
    /**
     * submit functionality and api call
     * @param {} e 
     */
    const onSubmit = (e: any) => {
        e.preventDefault();
        setSubmitted(true);
        if (name && email && mobile_no) {
            dispatch(
                myDetails(
                    name,
                    email,
                    mobile_no,
                    address,
                    current_password,
                    newpassword,
                    user_id?._id,
                )
            )
                .then((value: any) => {

                    if (value.data.status.code === 0) {
                        dispatch(alertActions.success(value.data.status.message));
                        navigate("/home")
                    } else {
                        dispatch(alertActions.error(value.data.status.message));
                    }
                })
                .catch((error: any) => {
                    console.log("Errorreg", error);
                });
        }
    }
    /**
 * go to home
 */
    const gotoHome = () => {
        navigate("/");
    }
    return {
        name,
        mobile_no,
        email,
        newpassword,
        current_password,
        submitted,
        address,
        gotoHome,
        changeHandler,
        onSubmit
    }
}
export default useMyAccount;