import HeaderScreen from "../../_common/Header/HeaderScreen";
import useForgotPassword from "./useForgotPassword";
import { logo } from "../../../assets/images";
import "./ForgotPassword.css";
import { withTranslation } from "react-i18next";
import FooterScreen from "../../_common/FooterScreen/FooterScreen";
const ForgotPassword = (props: any) => {
    const { t } = props;
    const { mobile, handleSubmit, handleChange, submitted } = useForgotPassword();
    const renderHeader = () => {
        return (
            <div className="main_login flt">
                <HeaderScreen />
            </div>
        );
    };
    //forgot password
    const mainForgotScreen = () => {
        return (
            <div className="login_main  flt">
                <div className="login_form forgot_screen">
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="main_block">
                                <div className="welcome flt">
                                    {t("welcome")}
                                </div>
                                <div className="mob_number flt">
                                    <label>{t("Mobile number")}</label>
                                    <input
                                        type="number"
                                        placeholder={t("mobile_placeholder")}
                                        name="mobile"
                                        value={mobile}
                                        onChange={handleChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSubmit(e);
                                            }
                                        }}

                                        onWheelCapture={(e: any) => {
                                            e.target.blur();
                                        }}
                                        className={submitted && !mobile ? " is-invalid" : ""}
                                    />
                                </div>
                                {submitted && !mobile && (
                                    <div className="invalid-feedback">
                                        {t("mobile_required")}
                                    </div>
                                )}


                                <div className="login_btn flt">
                                    <button className="login" onClick={handleSubmit}>{t("get_otp")}</button>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="image_sub flt">
                                <img src={logo} alt="pop_background" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <>
            {renderHeader()}
            {mainForgotScreen()}
            {renderFooter()}
        </>
    )
}
export default withTranslation()(ForgotPassword);