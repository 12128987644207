import FooterScreen from "../../_common/FooterScreen/FooterScreen"
import HeaderScreen from "../../_common/Header/HeaderScreen"
import { thankyou } from "../../../assets/images";
import "./ThankYou.css";
import { withTranslation } from "react-i18next";

const ThankYou = (props: any) => {
    const { t } = props;
    //render header
    const renderHeaderBar = () => {
        return (
            <div>
                <HeaderScreen />
            </div>

        )
    }


    //render footer
    const renderFooter = () => {
        return (
            <div className="main_login flt">
                <FooterScreen />
            </div>
        )
    }
    return (
        <>
            {renderHeaderBar()}
            <div className="thankyou_container flt">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="thankyou_box flt">
                            <div className="thankyou_image flt">
                                <img src={thankyou} alt="noimage" />
                            </div>
                            {t("thank")}
                            {/* <div className="emptycart_btn flt" onClick={() => prodPage()}>
                                <button>Continue Shopping</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </>
    )
}
export default withTranslation()(ThankYou);