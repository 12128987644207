import "./Menu.css";
import { logo } from "../../../assets/images";
import useMenu from "./useMenu";
import { useState } from "react";
import { withTranslation } from "react-i18next";
const Menu = (props: any) => {
    const { t } = props;
    /**
     * use menu hook
     */
    const {
        login_screen,
        handleLogout,
        user_id,
        menudata,
        cartCount,
        myAccount,
        myOrder,
        getMenuId,
        gotoHome,
        moveCart,
        aboutUs,
        contactUs } = useMenu();

    const [toggleBar, setToggleBar] = useState(false);
    const [showNav, setShowNav] = useState(false);
    /**
     * get navigation menu 
     */
    const openNav = () => {
        setShowNav(!showNav);
        setToggleBar(!toggleBar);
    }
    /**
     * 
     * @returns render menu main screen
     */
    const renderMenu = () => {
        return (
            <>
                <div className="menu_main flt">
                    <div className="container">
                        <div className="header_main flt">
                            <button className={'toggle_bar hide_desktop' + ((showNav) ? ' toggle_rotate' : '')} onClick={openNav}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <div className="logo" onClick={gotoHome}>
                                <img src={logo} alt="no_image" />

                            </div>
                            <div className="filler hide_desktop"></div>
                            <ul className={'menuH' + ((showNav) ? ' fixed_menu' : '')}>
                                {menudata && menudata?.category && menudata?.category.map((data: any) =>
                                    <li onClick={() => { getMenuId(data?.title); openNav() }}>{data?.title}</li>
                                )}
                                <li onClick={aboutUs}>{t("about_us")}</li>
                                <li onClick={contactUs}>{t("contact_us")}</li>
                            </ul>
                            <ul className={cartCount > 0 ? "cart_section cart_section1" : "cart_section"}>
                                <li>
                                    <i className="fas fa-tags"></i>
                                    <ul className="submenu dailyrate">
                                        <li><span>Gold 18KT</span><span> {t("rs")} {menudata?.daily_rates?.[0]?.gold18}</span></li>
                                        <li><span>Gold 22KT </span><span>{t("rs")} {menudata?.daily_rates?.[0]?.gold22}</span></li>
                                        <li><span>Silver</span><span> {t("rs")} {menudata?.daily_rates?.[0]?.silver}</span></li>

                                    </ul>
                                </li>
                                <li>
                                    {!user_id ?
                                        <i onClick={login_screen} className="fas fa-user"></i> :
                                        <i className="fas fa-user"></i>
                                    }
                                    <ul className="submenu">
                                        <li onClick={myAccount}>{t("my_account")}</li>
                                        <li onClick={myOrder}>{t("my_order")}</li>
                                        {user_id &&
                                            <li onClick={handleLogout}>{t("logout")}</li>
                                        }
                                    </ul>
                                </li>
                                <li>
                                    <b>{cartCount > 0 ? cartCount : ""}</b>
                                    <i onClick={moveCart} className="fas fa-shopping-cart"></i>

                                </li>

                            </ul>
                        </div>
                    </div>
                </div >

            </>
        )
    }
    return (
        <>
            {renderMenu()}
        </>
    )
}
export default withTranslation()(Menu);