import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { ProductService } from "../../../services";

const useProductDetails = () => {
    const location: any = useLocation();
    const [sku, setSku] = useState();
    var sku1 = window.location.href.split("/")[4]
    const [productDetails, setProductDetails] = useState<any>([]);
    const image_url = process.env.REACT_APP_MEDIA_DOMAIN;
    const navigate = useNavigate();
    const userData = useSelector((state: RootStateOrAny) => state?.userData);
    const isUser = userData ? Object.keys(userData).length : 0;
    const [loaderdata, setLoaderdata] = useState("notset");
    const [showOtp, setShowOtp] = useState(false);
    const dispatch = useDispatch();

    /**
     * set sku
     */
    useEffect(() => {
        setSku(location?.state?.sku);
    }, [location])

    //for scroll horizontalli likely images
    useEffect(() => {
        const slider: any = document.querySelector(".items");
        let isDown = false;
        let startX: any;
        let scrollLeft: any;

        slider.addEventListener("mousedown", (e: any) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e: any) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;

        });
        // eslint-disable-next-line
    }, []);


    /**
     * get product details thriugh sku
     */
    useEffect(() => {
        ProductService.getSingleProduct(sku ? sku : sku1).then((response) => {
            if (response?.data?.status?.code === 0) {
                setProductDetails(response?.data?.data);
                setLoaderdata("isfull")
            } else {
                setLoaderdata("isempty")
            }
        }).catch((err) => console.log(err))
        // eslint-disable-next-line
    }, [sku])


    //based on sku of likely image change image in the main screen
    const changeImage = (display: any) => {
        navigate(`/product-details/${display?.SKU}`, {
            state: { sku: display.SKU },
        });
    }


    /**
     * add to cart major functionality
     */
    const addCart = (data: any) => {
        if (isUser === 0) {
            // dispatch(alertActions.error(message?.login?.login));
            navigate("/Login")

        } else {
            ProductService.addToCart(data?.pro_details?.product_id).then((response) => {

                if (response?.data?.status?.code === 0) {
                    dispatch(alertActions.success(response?.data?.status?.message));
                    navigate("/cart")
                }
            })
        }
    }
    //close otp box on state change
    const openOtp = () => {
        document.body.classList.add("modal-open");
        setShowOtp(true);
    };

    //close otp box on state change
    const closeOtp = () => {
        document.body.classList.remove("modal-open");
        setShowOtp(false);
    };
    return {
        productDetails,
        image_url,
        loaderdata,
        showOtp,
        changeImage,
        addCart,
        closeOtp,
        openOtp
    }
}
export default useProductDetails;